import React, { useState, useEffect } from "react"
import useTranslations from "../../utils/useTranslations"
import { FaAndroid } from 'react-icons/fa';
import Logo from "../../components/logo"
import { ANDROID_LINK_FREE, ANDROID_LINK_PRO, getAndroidLink } from "../../utils/getAndroidLink";


const DownloadsAndroid = ({ pageContext: {text, locale} }) => {
	const t = useTranslations(text)
	const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

	const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)
	const [androidLinkPro, setAndroidLinkPro] = useState(ANDROID_LINK_PRO)

	useEffect(()=>{
		setAndroidLinkFree(getAndroidLink('free'))
		setAndroidLinkPro(getAndroidLink('pro'))
	},[])

    return (
		<div className="container my-5 pt-5">
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<h1><FaAndroid className="mb-2" />  <span className="ml-2">{t("BitTorrent Downloads for Android")}</span></h1>
					<div className="text-size-24 mt-2 mb-4 text-muted">{t("The most trusted Android torrent client in the World")}</div>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-12 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<Logo color="black" tag="h2" productName="Android" className="text-center text-md-left mb-0" fsMax={21.5}/>
						<div className="flex-shrink-0 align-items-center align-middle d-flex flex-column flex-md-row">
		              		<p className="d-inline-block text-size-32 my-auto mr-md-3">{t(`FREE`)} </p>
							<a href={androidLinkFree} id="android-downloads-free" className="index-mobile-google-play flex-shrink-0" target="_blank" rel="noopener noreferrer">
	                			<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
	              			</a>
						</div>
					</div>
					<p className="text-size-20 mb-2 text-muted">{t("The original free mobile torrent downloader.")}</p>
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-12 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<Logo color="black" tag="h2" productName="Android Pro" className="text-center text-md-left mb-0" fsMax={21.5}/>
						<div className="flex-shrink-0 align-items-center align-middle d-flex flex-column flex-md-row">
		              		<p className="d-inline-block text-size-32 my-auto mr-md-3">{t(`$3.99`)} </p>
							<a href={androidLinkPro} id="android-downloads-pro" className="index-mobile-google-play flex-shrink-0" target="_blank" rel="noopener noreferrer">
	                			<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent Pro for Android Google Play Store badge"></img>
	              			</a>
		          		</div>
					</div>
					<p className="text-size-20 mb-5 text-muted">{t("A premium torrent downloader for Android.")}</p>		
				</div>
			</div>
		</div>
    )
}

export default DownloadsAndroid